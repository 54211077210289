* {
  font-family: Gillroy, Helvetica, Arial, sans-serif;
}

:root {
  --reach-menu-button: 1;
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}

[data-reach-dialog-content] {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.2);
  display: flex;
  align-items: flex-start;
  outline: 0;
}
